import {Trend, TrendProps} from 'platform/components';

import {EMPTY_PLACEHOLDER} from 'shared';

const getColorAndLabel = (props: CompareTrendProps): TrendProps => {
  if (props.value > 0) {
    return {
      label: `+${props.formatter?.(props.value) ?? props.value}`,
      isPositive: !props.reverseColor,
      isNegative: props.reverseColor,
    };
  }
  if (props.value < 0) {
    return {
      label: props.formatter?.(props.value) ?? `${props.value}`,
      isPositive: props.reverseColor,
      isNegative: !props.reverseColor,
    };
  }
  return {
    label: EMPTY_PLACEHOLDER,
  };
};

interface CompareTrendProps {
  value: number;
  formatter?: (value: number) => string | null;
  reverseColor?: boolean;
  isBig?: boolean;
}

export function CompareTrend(props: CompareTrendProps) {
  const trendParams = getColorAndLabel(props);

  return (
    <Trend
      label={trendParams.label}
      isPositive={trendParams.isPositive}
      isNegative={trendParams.isNegative}
      isTransparent={trendParams.isTransparent}
      isSubtle
      isSmall={!props.isBig}
    />
  );
}
