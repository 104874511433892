import {equals, isNotNil} from 'ramda';

import {FilterFormData, FiltersRequestBody} from '@price-report/shared';

const PERCENTAGE_MULTIPLIER = 100;

function mapIsVatDeductible(isVatDeductible: string[]) {
  if (equals(isVatDeductible, ['YES'])) {
    return true;
  }
  if (equals(isVatDeductible, ['NO'])) {
    return false;
  }
  return undefined;
}

export const formValuesToRequestValues = (values: FilterFormData): FiltersRequestBody => ({
  location:
    isNotNil(values.address) && isNotNil(values.radius)
      ? {lat: values.address.lat, lon: values.address.lng, distance: values.radius}
      : undefined,
  bodyStyle: values.bodyStyle ?? undefined,
  title: values.title ?? undefined,
  drive: values.drive ?? undefined,
  fuelType: values.fuelType ?? undefined,
  isVatDeductible: mapIsVatDeductible(values.isVatDeductible),
  hideSoldCars: !values.showSoldCars ? true : undefined,
  hideStockCars: !values.showStockCars ? true : undefined,
  transmission: values.transmission ?? undefined,
  mileage: {
    gte: values.mileageGte ?? undefined,
    lte: values.mileageLte ?? undefined,
  },
  power: {
    gte: values.powerGte ?? undefined,
    lte: values.powerLte ?? undefined,
  },
  price: {
    gte: values.priceGte ?? undefined,
    lte: values.priceLte ?? undefined,
  },
  firstRegistrationOnYear: {
    gte: values.firstRegistrationOnYearGte ?? undefined,
    lte: values.firstRegistrationOnYearLte ?? undefined,
  },
  featuresLevel: {
    gte: isNotNil(values.featureScoreGte)
      ? values.featureScoreGte / PERCENTAGE_MULTIPLIER
      : undefined,
    lte: isNotNil(values.featureScoreLte)
      ? values.featureScoreLte / PERCENTAGE_MULTIPLIER
      : undefined,
  },
  featureTier: values.featureTiers ?? undefined,
});
