import {match} from 'ts-pattern';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {FilterFormData} from '@price-report/shared';

export const parseSearchParamsToFilterFormData = (
  searchParams: URLSearchParams
): FilterFormData => ({
  address:
    isNotNilOrEmpty(searchParams.get('label')) &&
    isNotNilOrEmpty(searchParams.get('lat')) &&
    isNotNilOrEmpty(searchParams.get('lng'))
      ? {
          label: searchParams.get('label') as string,
          lat: Number(searchParams.get('lat')),
          lng: Number(searchParams.get('lng')),
        }
      : null,
  radius: isNotNilOrEmpty(searchParams.get('radius')) ? Number(searchParams.get('radius')) : null,
  title: isNotNilOrEmpty(searchParams.get('title')) ? searchParams.get('title') : null,
  showSoldCars: match(searchParams.get('showSoldCars'))
    .with('true', () => true)
    .with('false', () => false)
    .otherwise(() => null),
  showStockCars: match(searchParams.get('showStockCars'))
    .with('true', () => true)
    .with('false', () => false)
    .otherwise(() => null),
  priceGte: isNotNilOrEmpty(searchParams.get('priceGte'))
    ? Number(searchParams.get('priceGte'))
    : null,
  priceLte: isNotNilOrEmpty(searchParams.get('priceLte'))
    ? Number(searchParams.get('priceLte'))
    : null,
  isVatDeductible: match<string | null, ('YES' | 'NO')[]>(searchParams.get('isVatDeductible'))
    .with('YES', () => ['YES'])
    .with('NO', () => ['NO'])
    .otherwise(() => []),
  firstRegistrationOnYearGte: isNotNilOrEmpty(searchParams.get('firstRegistrationOnYearGte'))
    ? Number(searchParams.get('firstRegistrationOnYearGte'))
    : null,
  firstRegistrationOnYearLte: isNotNilOrEmpty(searchParams.get('firstRegistrationOnYearLte'))
    ? Number(searchParams.get('firstRegistrationOnYearLte'))
    : null,
  powerGte: isNotNilOrEmpty(searchParams.get('powerGte'))
    ? Number(searchParams.get('powerGte'))
    : null,
  powerLte: isNotNilOrEmpty(searchParams.get('powerLte'))
    ? Number(searchParams.get('powerLte'))
    : null,
  mileageGte: isNotNilOrEmpty(searchParams.get('mileageGte'))
    ? Number(searchParams.get('mileageGte'))
    : null,
  mileageLte: isNotNilOrEmpty(searchParams.get('mileageLte'))
    ? Number(searchParams.get('mileageLte'))
    : null,
  featureScoreGte: isNotNilOrEmpty(searchParams.get('featureScoreGte'))
    ? Number(searchParams.get('featureScoreGte'))
    : null,
  featureScoreLte: isNotNilOrEmpty(searchParams.get('featureScoreLte'))
    ? Number(searchParams.get('featureScoreLte'))
    : null,
  drive: isNotNilOrEmpty(searchParams.get('drive'))
    ? (searchParams.get('drive') as string).split(',')
    : [],
  transmission: isNotNilOrEmpty(searchParams.get('transmission'))
    ? (searchParams.get('transmission') as string).split(',')
    : [],
  bodyStyle: isNotNilOrEmpty(searchParams.get('bodyStyle'))
    ? (searchParams.get('bodyStyle') as string).split(',')
    : [],
  fuelType: isNotNilOrEmpty(searchParams.get('fuelType'))
    ? (searchParams.get('fuelType') as string).split(',')
    : [],
  featureTiers: isNotNilOrEmpty(searchParams.get('featureTiers'))
    ? (searchParams.get('featureTiers') as string).split(',')
    : [],
});
