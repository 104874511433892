import {Box, HStack, Show, Spinner, VStack} from 'platform/foundation';

import {useEffect} from 'react';

import {count} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {featureFlags, PriceReportType} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {useFilter} from '../../../../hooks/FilterContext';
import {CatalogueType} from '../../../../hooks/useCatalogue';
import {usePriceReportSearchParams} from '../../../../hooks/usePriceReportSearchParams';
import {ChartLegend} from './components/ChartLegend';
import {ChartTypeSwitch} from './components/ChartTypeSwitch';
import {ChartView} from './components/ChartView';
import {MapView} from './components/MapView';
import {SelectedVehicleCard} from './components/SelectedVehicleCard';
import {defaultChartType} from './consts/defaultChartType';
import {useSelectedVehicleIdsQueryState} from './hooks/useSelectedVehicleIdsQueryState';
import {useVisibleAvailabilitiesQueryState} from './hooks/useVisibleAvailabilitiesQueryState';
import {ExtendedStatisticsCar} from './types';
import {filterCarsByAvailability} from './utils/filterCarsByAvailability';
import {isOnStock} from './utils/isOnStock';
import {isSoldIn30Days} from './utils/isSoldIn30Days';
import {isSoldIn90Days} from './utils/isSoldIn90Days';

export interface StatisticsChartProps extends TestIdProps {
  priceReport: PriceReportType;
  data: ExtendedStatisticsCar[];
  catalogue: CatalogueType;
  isLoading?: boolean;
}

const CHART_MIN_HEIGHT = 110;

export function StatisticsChart(props: StatisticsChartProps) {
  const {filter} = useFilter();
  const [selectedVehicleIds, setSelectedVehicleIds] = useSelectedVehicleIdsQueryState();
  const [visibleAvailabilities, setVisibleAvailabilities] = useVisibleAvailabilitiesQueryState([
    'onStock',
    'soldIn30Days',
    'soldIn90Days',
  ]);
  const {chartVariant, setChartVariant} = usePriceReportSearchParams();
  const filteredCars = filterCarsByAvailability(props.data, visibleAvailabilities);

  useEffect(() => {
    if (
      isNotNil(selectedVehicleIds) &&
      ![
        ...filteredCars.map((vehicle) => vehicle.ad_id),
        ...(props.priceReport.neighborAdIds ?? []),
      ].some((ad_id) => selectedVehicleIds.includes(ad_id))
    ) {
      setSelectedVehicleIds(null);
    }
  }, [
    filter,
    filteredCars,
    selectedVehicleIds,
    setSelectedVehicleIds,
    props.priceReport.neighborAdIds,
  ]);

  const onStockCount = count(isOnStock, props.data);
  const soldIn30DaysCount = count(isSoldIn30Days, props.data);
  const soldIn90DaysCount = count(isSoldIn90Days, props.data);

  return (
    <VStack spacing={4} grow={1}>
      <Box paddingLeft={[0, 0, 19, 19]}>
        <HStack justify="space-between" align="center" spacing={4} wrap>
          <ChartLegend
            value={visibleAvailabilities}
            onChange={setVisibleAvailabilities}
            onStockCount={onStockCount}
            soldIn30DaysCount={soldIn30DaysCount}
            soldIn90DaysCount={soldIn90DaysCount}
          />
          <ChartTypeSwitch
            chartVariant={chartVariant}
            setChartVariant={setChartVariant}
            defaultChartType={defaultChartType}
            data-testid={suffixTestId('chartTypeSwitch', props)}
          />
        </HStack>
      </Box>
      <Box
        minHeight={CHART_MIN_HEIGHT}
        overflow="hidden"
        flexGrow={1}
        position="relative"
        data-testid={suffixTestId('chartWrapper', props)}
      >
        <Show when={chartVariant !== 'mapView'}>
          <ChartView
            chartVariant={chartVariant}
            priceReport={props.priceReport}
            data={filteredCars}
            selectedVehicleIds={selectedVehicleIds}
            setSelectedVehicleIds={setSelectedVehicleIds}
            data-testid={suffixTestId('chartView', props)}
          />
        </Show>
        <Show when={chartVariant === 'mapView'} whenFeatureEnabled={featureFlags.PR_MAP_VIEW}>
          <MapView
            priceReport={props.priceReport}
            data={filteredCars}
            selectedVehicleIds={selectedVehicleIds}
            setSelectedVehicleIds={setSelectedVehicleIds}
            data-testid={suffixTestId('mapView', props)}
          />
        </Show>
        <Show when={props.isLoading}>
          <Box position="absolute" top={0} left={25} right={0} bottom={20}>
            <VStack height="100%" align="center" justify="center">
              <Spinner />
            </VStack>
          </Box>
        </Show>
      </Box>
      {selectedVehicleIds?.map((selectedVehicleId, index) => (
        <SelectedVehicleCard
          key={selectedVehicleId}
          vehicleId={selectedVehicleId}
          priceReport={props.priceReport}
          catalogue={props.catalogue}
          shouldScrollIntoView={index === 0}
        />
      ))}
    </VStack>
  );
}
